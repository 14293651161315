h1 {
    font-weight: 700;
    font-size: 3em;
    margin-bottom: 0;
    line-height: 1;
}

h2 {
    font-weight: 700;
    font-size: 1.5em;
    line-height: 1;
    & + p {
        margin-top: -0.25em;
    }
}

.subtitle {
    margin-top: 0;
    font-size: 1.8em;
}

.meta {
    display: grid;
    grid-template-columns: auto 1fr;
    font-size: 1.2em;
    dt {
        font-weight: bold;
        text-align: right;
    }
    dd {
        margin-left: 1em;
        margin-bottom: 0.1em;
    }
}

.info {
    h3 {
        font-weight: bold;
        margin-bottom: 0.25em;
        margin-top: 0.75em;
    }
    p {
        margin-bottom: 0.5em;
    }
}

.link {
    border-bottom: 2px solid #a2cc39;
    cursor: pointer;
}