.page {
    $b: &;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 4vw;
}

.header {
    $b: &;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0 40px 0;
    &_logo {
        max-width: 165px;
    }
    &_title {
        font-size: 2em;
        color: #858585;
    }
    &_new {
        font-size: 1.4em;
        color: #858585;
        border-bottom: 2px solid #a2cc39;
        &::after {
            content: '';
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAABQCAYAAAC0wU3eAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAABOlJREFUeJztmj1sG2Ucxp//2VHJBxmQkNpKYUFiYEaIhY8sIAYWJAx357RpB6TaLgMLNE1i56tphRgQdiRLBZHEd5bcARHEiAoTCAlYqRADVSNFYkciudzTIXaxyp19H+/ruCjPFvvuef6/vP973/fOB/yPJHFPKN++nT19794zMIyJw5GRu6Vcbk9HYUkUGaZer48cjD8+B/A9EXmi8zmBHwm+f9m2f9BTYnRFgvm41Rp9bN/7EoLXAg8gPRFYBdu+pbS6mDL6HVCv74ydOvB2QkEAQCTrE80Nx3lLaXUx1XNkPtraGh/NZL8WYDqKGclDGGKVLKulprx4CoWptloTcuB9A+ClOIbHCRTYZiQF+56LmCAAICIZ+HSrrptLXV1MBcLUXPd1EbyR1LQDVGs0305eWnwFTwCU1EWISIbwnUECBcKI4GkV5oMGCr5mgH1VASKSgQym5ULWGf6sPGcAQIEwhu/fJHmoPEvofuo47yj27QoIUGFm5g4E8zryDIijCyh0O1O0rBsEbujINCBO1XVN1cY9tzMkpeY21wX4QHUwAJ+CfMmymqoMe240RYRFy7wC4rqqwO5sIRoqR6jvrllEWLDNOZ1AG42mpcIs8s0ZSdlwmtcg+FBF8EPyhTJTyJtuGpO+I9NRZ4QIrqcJDKuDwu2q49hpTGI/AziaFNw1gVxJExwin+C5km07SU6ODQPoB4LgfNGyGnFPTAQDPLiGViGYS+rRQ4mAEsMAwwcUeQIIUntSmCexlsYnRAaIzZrr5qOfkFIiwqJtLugCos+tqECp2qxbJKXmNFdEcFWVZ5c3AeN8KW9u9zpOGUw7VDZcdxkQ5TvuKEBKYdqhxwaU+pp5WCLCgmUtAlzV4C2Av1lznHNB3yuHaYceARErGryFxBdBQMrbrFvtdWgJggUN3v9pOa0w7VCpOc2KCBY1mHtiyPMFy/oV0NRm3WqvQxUSyxrMs77/7z9JO8xRpk4gvloulw1gQDDAEVApb5UJ7ij1hYxNTU2NAwOEAYDqtnsRTP5APlDkP5OTk38DA4SpbrsXIbx5tFaoEwXf53K5Q2BAMLpAAIC+8eA2XjuMVhBi+fKM+V3nb63rjE4QENcLtjknIux8pA1m0CCApjbT2lrgehAIoGFkdIMULetqEAigGEZza10r2OZ8GAigEOa4QQAgoyKr5jgXAHymafpdK9rmQj8QQMHI1BznAnn8IEBKmGECAVLA6AQBuFqwrMU4IEBCmGEEARLAVBuNWUA+1zRrrRRss5wEBIgJM8wgQAyYYQcBIu7NdIKQWFYBAkQYGd0gRdusqAAB+sBobS1wqWjbFZWO4e9ouq4Jn86jAgKEwNQ3W095mYPfIDKqOlAXCBAyARxkvHcfNRAg9LVGvqA+Si8IEP7C6ZjKEBIV3SBA2DuawruqAkhUSnlrSZVfLwWPjMhXauxZHhQIEALz15kzt0j8lM6a5aJtq3/q30OBMEvT017Gy74J8o9ktoMHAXrszS7N5nYNb+RlEr/HMSS4eBwgQJ+N5qXZ3C4NvELgThQzgosl21b+o2xURdqqVFut0zjwvhXg2bBjSCyU8pbyn8vjKNItQCmX2zs1kn2RxBYAv/s7grs+aB43CJDgtvmTVuvJkf3D5yD+hAB/7p09+8vS9LSno7gTnehEJzrRiYZJ9wE+7lpS3DuPnwAAAABJRU5ErkJggg==);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center right;
            width: 0.8em;
            height: 0.8em;
            display: inline-block;
            vertical-align: middle;
        }
    }
    &_inner {
        display: flex;
        align-items: center;
        > * {
            &:not(:first-child) {
                margin-left: 1.5em;
            }
        }
    }
}

.main {
    $b: &;
    border-top: 1px solid #a2cc39;
    padding: 40px 0 60px 0;
}

.full {
    $b: &;
    padding-bottom: 0;
    width: calc(100vw - 30px) !important;
    height: calc(100vh - 30px) !important;
    .ant-modal-content {
        overflow: hidden;
        height: 100%;
    }
    .ant-modal-body {
        height: 100%;
        padding: 0;
    }
    .split {
        width: 100%;
        height: 100%;
    }
}

.document {
    $b: &;
    padding-bottom: 0;
    width: calc(100vw - 30px) !important;
    height: calc(100vh - 30px) !important;
    max-width: 1000px;
    .ant-modal-content {
        overflow: hidden;
        height: 100%;
    }
    .ant-modal-body {
        overflow: auto;
        height: 100%;
        padding: 0;
    }
}

.frame {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    > * {
        margin: auto;
    }
}

.split {
    $b: &;
    display: grid;
    grid-template-columns: 34% 66%;
    &_sidebar {
        background-color: #eee;
        border-right: 1px solid #ddd;
        padding: 25px;
        overflow: auto;
    }
    &_content {
        overflow: auto;
        position: relative;
    }
}

.agree {
    $b: &;
    display: flex;
    gap: 0.25rem;
    justify-content: center;
    input {
        max-width: 250px;
        text-align: center;
    }
}