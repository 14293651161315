.row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.responses {
    $b: &;
    display: flex;
    flex-wrap: wrap;
    > * {
        flex-basis: calc(50% - 6px);
        flex-grow: 1;
        &:nth-child(odd) {
            margin-right: 3px;
        }
        &:nth-child(even) {
            margin-left: 3px;
        }
        &:nth-child(1), &:nth-child(2) {
            margin-bottom: 6px;
        }
    }
}

.react-pdf__Page {
    & + & {
        border-top: 1px solid #eee;
    }
}

$spacing: 20px;

.form-item {
    $b: &;
    * + & {
        margin-top: $spacing * 0.5;
    }
    &_label {
        display: block;
        margin-bottom: 5px;
        // font-weight: 600;
        &:empty {
            display: none;
            &::before {
                content: '\00a0';
            }
        }
    }
    &_content {
        > .ant-checkbox-wrapper,
        > .ant-radio-group > .ant-radio-wrapper {
            line-height: 32px;
        }
    }
    &_info {
        display: block;
        margin-top: 5px;
        color: #999999;
        &:empty {
            display: none;
            &::before {
                content: '\00a0';
            }
        }
        & + & {
            margin-top: 0;
        }
    }
    &.is-input {
        &.is-required {
            > #{$b}_label {
                &::after {
                    color: #f39200;
                    content: ' *';
                }
            }
        }
    }
    &.is-error {
        > #{$b}_content > #{$b}_info {
            color: #e30613;
        }
        // .ant-input,
        // .ant-input-number,
        // .ant-select-selector,
        // .ant-input-affix-wrapper,
        // .rc-color-picker-trigger {
        //     border-color: #e30613;
        // }
    }
}

.form-shim {
    line-height: 32px;
}