.ant-card-actions {
    background-color: transparent;
}
.ant-modal-footer {
    padding: 12px 24px;
}
.ant-modal-close {
    background-color: #eee;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding-right: 12px;
    &::after {
        content: 'Close';
        font-weight: normal;
        font-size: 1.2em;
    }
    &:hover {
        background-color: #e6e6e6;
    }
}
.ant-modal-close-x {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-right: -5px;
}